import URL from "constant/url";

export default function HeaderBanner() {
  return (
    <div className="rts-ht rts-ht__bg">
      <div className="container">
        <div className="row">
          <div className="rts-ht__wrapper">
            <div className="rts-ht__email">
              <a href="mailto:contact@cicloud.com">
                <img
                  src="assets/images/icon/email.svg"
                  alt=""
                  className="icon"
                />
                contact@cicloud.com
              </a>
            </div>
            <div className="rts-ht__promo">
              <p>
                <img
                  className="icon"
                  src="assets/images/icon/tag--group.svg"
                  alt=""
                />{" "}
                Ưu đãi hấp dẫn: Giá chỉ từ <strong>120.000/tháng</strong>
              </p>
            </div>
            <div className="rts-ht__links">
              <div className="live-chat-has-dropdown">
                <a href={URL.Login} target="_blank" className="live__chat" rel="noreferrer">
                  <img
                    src="assets/images/icon/person.svg"
                    alt=""
                    className="icon"
                  />
                  Đăng nhập
                </a>
              </div>
              <div className="login-btn-has-dropdown">
                <a href={URL.Register} target="_blank" className="login__link" rel="noreferrer">
                  Đăng ký
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
