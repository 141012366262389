/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ProxyCategory() {
  const navigate = useNavigate();
  return (
    <li className="menu-item hostie-has-dropdown mega-menu">
      <a href="" className="hostie-dropdown-main-element">
        Proxy
      </a>
      <div className="rts-mega-menu">
        <div className="wrapper">
          <div className="row g-0">
            <div className="col-lg-6">
              <ul className="mega-menu-item">
                <p style={{ color: "#083A5E" }}>KHU VỰC VIỆT NAM</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyVN);
                    }}
                  >
                    <img
                      src="assets/flags/vietnam.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Việt Nam (VN)</p>
                    </div>
                  </a>
                </li>
                <p style={{ color: "#083A5E" }}>KHU VỰC CHÂU ÂU</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyUK);
                    }}
                  >
                    <img
                      src="assets/flags/united-kingdom.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Anh (UK)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyDE);
                    }}
                  >
                    <img
                      src="assets/flags/germany.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Đức (DE)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyFR);
                    }}
                  >
                    <img
                      src="assets/flags/france.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Pháp (FR)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyNL);
                    }}
                  >
                    <img
                      src="assets/flags/netherlands.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Hà Lan (NL)</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="mega-menu-item">
                <p style={{ color: "#083A5E" }}>KHU VỰC CHÂU Á</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxySG);
                    }}
                  >
                    <img
                      src="assets/flags/singapore.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Singapore (SG)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyAU);
                    }}
                  >
                    <img
                      src="assets/flags/australia.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Úc (AU)</p>
                    </div>
                  </a>
                </li>
                <p style={{ color: "#083A5E" }}>KHU VỰC ÂU MỸ</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyUS);
                    }}
                  >
                    <img
                      src="assets/flags/united-states.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Mỹ (US)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListProxyCA);
                    }}
                  >
                    <img
                      src="assets/flags/canada.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>Proxy Canada (CA)</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
