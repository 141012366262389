import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function OurBestServices() {
  return (
    <div className="rts-services-area area-3 service-bg pt--120 pb-120">
      <div className="container">
        <div className="section-title-area text-center">
          <h2
            className="section-title sal-animate"
            data-sal="slide-down"
            data-sal-delay={200}
            data-sal-duration={800}
          >
            Đa dạng tùy chọn, đa dạng Hệ điều hành
          </h2>
          <p
            className="desc sal-animate"
            data-sal="slide-down"
            data-sal-delay={300}
            data-sal-duration={800}
          >
            Quý khách có thể tùy chọn cài đặt máy chủ tùy theo nhu cầu. Toàn bộ
            quá trình được vận hành tự động đảm bảo an toàn, bảo mật thông tin
            khách hàng.
          </p>
        </div>
        <div className="section-inner">
          <div className="row  g-5">
            <div
              className="col-lg-4 col-md-6 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay={400}
              data-sal-duration={800}
            >
              <div className="service-wrapper">
                <div className="icon">
                  <img src="assets/images/service/cloud__hosting2.png" alt="" />
                </div>
                <div className="content">
                  <h4 className="title">VPS</h4>
                  <p className="desc">
                    Ổ cứng SSD NVMe siêu nhanh, phục vụ các mục đích mmo
                  </p>
                  <a href={URL.ListServerVN} className="view-more-btn">
                    Xem thêm <i className="fa-regular fa-arrow-right" />
                  </a>
                </div>
                <div className="wrapper-shape">
                  <img
                    src="assets/images/service/service-bg-shape.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay={500}
              data-sal-duration={800}
            >
              <div className="service-wrapper">
                <div className="icon">
                  <img src="assets/images/service/web__hosting2.png" alt="" />
                </div>
                <div className="content">
                  <h4 className="title">Dedicated Server</h4>
                  <p className="desc">
                    Cung cấp server riêng với 100% sức mạnh cho khách hàng.
                  </p>
                  <a href={URL.ListDedicatedServerEU} className="view-more-btn">
                    Xem thêm <i className="fa-regular fa-arrow-right" />
                  </a>
                </div>
                <div className="wrapper-shape">
                  <img
                    src="assets/images/service/service-bg-shape.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 col-sm-6 sal-animate"
              data-sal="slide-down"
              data-sal-delay={600}
              data-sal-duration={800}
            >
              <div className="service-wrapper">
                <div className="icon">
                  <img src="assets/images/service/dedicated.png" alt="" />
                </div>
                <div className="content">
                  <h4 className="title">Proxy</h4>
                  <p className="desc">
                    IP đa dạng bao gồm nhiều nước như : US, SG, AU, VN, FR, DE,
                    UK, CA.
                  </p>
                  <a href={URL.ListProxyVN} className="view-more-btn">
                    Xem thêm <i className="fa-regular fa-arrow-right" />
                  </a>
                </div>
                <div className="wrapper-shape">
                  <img
                    src="assets/images/service/service-bg-shape.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-shape-area">
        <img
          className="shape-one"
          src="assets/images/service/service-bg-shape2.svg"
          alt=""
        />
        <img
          className="shape-two"
          src="assets/images/service/service-bg-shape3.svg"
          alt=""
        />
      </div>
    </div>
  );
}
