/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function S5ProxyCategory() {
  const navigate = useNavigate();
  return (
    <li className="menu-item hostie-has-dropdown mega-menu">
      <a href="#" className="hostie-dropdown-main-element">
        S5 Proxy
      </a>
      <div className="rts-mega-menu">
        <div className="wrapper">
          <div className="row g-0">
            <div className="col-lg-12">
              <ul className="mega-menu-item">
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.List922S5Proxy);
                    }}
                  >
                    <img
                      src="assets/partner/922.png"
                      alt="server"
                      width={75}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>922 Proxy</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListPIAS5Proxy);
                    }}
                  >
                    <img
                      src="assets/partner/pia.png"
                      alt="server"
                      width={75}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>PIA Proxy</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
