import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function WhyChooseUs() {
  return (
    <div className="rts-feature-area pt--120 pb-120">
      <div className="container">
        <div className="section-title-btn-area">
          <div className="section-title-area text-start">
            <h3
              className="section-title font-40 sal-animate"
              data-sal="slide-down"
              data-sal-delay={200}
              data-sal-duration={800}
            >
              Tại Sao Chọn CICLOUD
            </h3>
            <p
              className="desc sal-animate"
              data-sal="slide-down"
              data-sal-delay={300}
              data-sal-duration={800}
            >
              Chúng tôi tự tin là đối tác có thể đem đến cho bạn dịch vụ chuyên
              nghiệp với giá cả hợp lý nhất.
            </p>
          </div>
          <a href={URL.BuyProduct} className="title-btn rts-btn">
            Mua ngay
          </a>
        </div>
        <div className="section-inner">
          <div className="feature-wrapper">
            <div className="row g-5 mt--60 inner-separator">
              <div className="col-lg-3 col-md-6 col-sm-6 mt--0 pt--50">
                <div className="feature-wrapper text-center">
                  <div className="overlay" />
                  <div className="icon">
                    <img src="assets/images/feature/feature-01.png" alt="" />
                  </div>
                  <div className="content">
                    <h4 className="title">Tiêu Chuẩn Quốc Tế</h4>
                    <p className="desc">
                      Máy chủ được đặt và vận hành tại các Trung tâm dữ liệu
                      Tier 3 chuẩn quốc tế.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mt--0 pt--50">
                <div className="feature-wrapper text-center">
                  <div className="overlay" />
                  <div className="icon">
                    <img src="assets/images/feature/feature-02.png" alt="" />
                  </div>
                  <div className="content">
                    <h4 className="title">Bảo mật dữ liệu</h4>
                    <p className="desc">
                      Dữ liệu khách hàng được mã hóa và bảo mật.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mt--0 pt--50">
                <div className="feature-wrapper text-center">
                  <div className="overlay" />
                  <div className="icon">
                    <img src="assets/images/feature/feature-03.png" alt="" />
                  </div>
                  <div className="content">
                    <h4 className="title">Hỗ Trợ 24/7</h4>
                    <p className="desc">
                      Đội ngũ IT, Chăm sóc khách hàng chuyên nghiệp, sẵn sàng
                      cho mọi tình huống.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 mt--0 pt--50">
                <div className="feature-wrapper text-center">
                  <div className="overlay" />
                  <div className="icon">
                    <img src="assets/images/feature/feature-04.png" alt="" />
                  </div>
                  <div className="content">
                    <h4 className="title">Chính Sách Hoàn Trả</h4>
                    <p className="desc">
                      Nếu quý khách không hài lòng với dịch vụ, chúng tôi sẽ hỗ
                      trợ hoàn lại tiền.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
