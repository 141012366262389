import React from "react";

interface IGuide {
  children: React.ReactNode;
}
export default function GuideRightContent(props: IGuide) {
  const { children } = props;
  return (
    <div className="col-lg-4">
      <div className="rts-sidebar">
        {/* single widget start */}
        <div className="rts-single-widget recentpost-widget">
          <h4 className="widget-title">Bài đăng gần đây</h4>
          <div className="recent-posts">
            {children}
          </div>
        </div>
        {/* single widget end */}
      </div>
    </div>
  );
}
