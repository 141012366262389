/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import HeaderBanner from "./HeaderBanner";
import ServerCategory from "./ServerCategory";
import DedicatedServerCategory from "./DedicatedServerCategory";
import { useNavigate } from "react-router-dom";
import ProxyCategory from "./ProxyCategory";
import S5ProxyCategory from "./S5ProxyCategory";

export default function Header() {
  const navigate = useNavigate();

  return (
    <header className="rts-header style-one header__default">
      <HeaderBanner />
      <div className="container">
        <div className="row">
          <div className="rts-header__wrapper">
            {/* FOR LOGO */}
            <div className="rts-header__logo">
              <a onClick={() => navigate(URL.Home)} style={{cursor: 'pointer'}} className="site-logo">
                <img
                  className="logo-white"
                  src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                  alt="cicloud"
                />
                <img
                  className="logo-dark"
                  src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`}
                  alt="cicloud"
                />
              </a>
            </div>
            {/* FOR NAVIGATION MENU */}
            <nav className="rts-header__menu" id="mobile-menu">
              <div className="hostie-menu">
                <ul className="list-unstyled hostie-desktop-menu">
                  <li className="menu-item">
                    <a onClick={() => navigate(URL.Home)} className="hostie-dropdown-main-element">
                      Trang chủ
                    </a>
                  </li>
                  <ServerCategory />
                  <DedicatedServerCategory />
                  <ProxyCategory/>
                  <S5ProxyCategory/>
                  <li className="menu-item">
                    <a
                      onClick={() => navigate(URL.Guide)}
                      className="hostie-dropdown-main-element"
                    >
                      Hướng dẫn
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      onClick={() => navigate(URL.Agreement)}
                      className="hostie-dropdown-main-element"
                    >
                      Điều khoản
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      onClick={() => navigate(URL.SLA)}
                      className="hostie-dropdown-main-element"
                    >
                      SLA
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      onClick={() => navigate(URL.Contact)}
                      className="hostie-dropdown-main-element"
                    >
                      Liên hệ
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* FOR HEADER RIGHT */}
            <div className="rts-header__right">
              <button id="menu-btn" className="mobile__active menu-btn">
                <i className="fa-sharp fa-solid fa-bars" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
