/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  actionGetListPlan,
  selectListPlanDetail,
} from "components/FeaturePlan/slice";
import { MANAGEMENT_URL, SITE_NAME } from "constant/app";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";

export default function ListFeaturePlan() {
  const dispatch = useAppDispatch();
  const listPlanDetail = useAppSelector(selectListPlanDetail);

  useEffect(() => {
    dispatch(actionGetListPlan({ featured: true, site_name: SITE_NAME }));
  }, [dispatch]);

  return (
    <div className="rts-pricing-plan" style={{ paddingBottom: "40px", paddingTop: "40px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="rts-section  text-center">
            <h2
              className="rts-section__title sal-animate"
              data-sal="slide-down"
              data-sal-delay={100}
              data-sal-duration={800}
            >
              Dịch vụ nổi bật
            </h2>
            <p
              className="rts-section__description w-450 sal-animate"
              data-sal="slide-down"
              data-sal-delay={300}
              data-sal-duration={800}
            >
              Chúng tôi cung cấp các gói sản phẩm - dịch vụ ưu việt, cấu hình
              tối ưu với giá cả hợp lý.
            </p>
          </div>
        </div>
        <div className="row">
          {/* PRICING PLAN */}
          <div className="price__content open" id="monthly" style={{}}>
            <div className="row g-30 monthly">
              {/* single card */}
              {listPlanDetail.map((item, index) => (
                <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                  <div className="card-plan active">
                    <div className="popular-tag">nổi bật</div>
                    <div className="card-plan__package">
                      <div className="icon">
                        <img
                          src="assets/images/pricing/business.svg"
                          height={30}
                          width={30}
                          alt=""
                        />
                      </div>
                      <h4 className="package__name">VPS {item.region}</h4>
                    </div>
                    <h5 className="card-plan__price">
                      <sup>$</sup> {item.price} <sub>VNĐ/Tháng</sub>
                    </h5>
                    <div className="card-plan__cartbtn">
                      <a
                        href={`${MANAGEMENT_URL}/buying?type=VPS&nation=${item.region}`}
                      >
                        Mua ngay
                      </a>
                    </div>
                    <div className="card-plan__feature">
                      <ul className="card-plan__feature--list">
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" /> CPU:{" "}
                            {item.cpu}
                          </span>
                        </li>
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" />{" "}
                            <li>RAM: {item.ram}</li>
                          </span>
                        </li>
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" /> SSD:{" "}
                            {item.ssd}
                          </span>
                        </li>
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" /> IPv4:{" "}
                            {item.ipv4}
                          </span>
                        </li>
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" />
                            Bandwidth: {item.bandwidth}
                          </span>
                        </li>
                        <li className="card-plan__feature--list-item">
                          <span className="text">
                            <i className="fa-regular fa-check" />
                            Ethernet port: {item.ethernet_port}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
              {/* single card end */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
