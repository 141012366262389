/* eslint-disable jsx-a11y/anchor-is-valid */
import URL from "constant/url";
import { useNavigate } from "react-router-dom";

export default function ServerCategory() {
  const navigate = useNavigate();

  return (
    <li className="menu-item hostie-has-dropdown mega-menu">
      <a href="" className="hostie-dropdown-main-element">
        VPS
      </a>
      <div className="rts-mega-menu">
        <div className="wrapper">
          <div className="row g-0">
            <div className="col-lg-6">
              <ul className="mega-menu-item">
                <p style={{ color: "#083A5E" }}>KHU VỰC VIỆT NAM</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerVN);
                    }}
                  >
                    <img
                      src="assets/flags/vietnam.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Việt Nam (VN)</p>
                    </div>
                  </a>
                </li>
                <p style={{ color: "#083A5E" }}>KHU VỰC CHÂU ÂU</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerUK);
                    }}
                  >
                    <img
                      src="assets/flags/united-kingdom.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Anh (UK)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerDE);
                    }}
                  >
                    <img
                      src="assets/flags/germany.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Đức (DE)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerFR);
                    }}
                  >
                    <img
                      src="assets/flags/france.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Pháp (FR)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerNL);
                    }}
                  >
                    <img
                      src="assets/flags/netherlands.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Hà Lan (NL)</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <ul className="mega-menu-item">
                <p style={{ color: "#083A5E" }}>KHU VỰC CHÂU Á</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerSG);
                    }}
                  >
                    <img
                      src="assets/flags/singapore.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Singapore (SG)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerAU);
                    }}
                  >
                    <img
                      src="assets/flags/australia.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Úc (AU)</p>
                    </div>
                  </a>
                </li>
                <p style={{ color: "#083A5E" }}>KHU VỰC ÂU MỸ</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerUS);
                    }}
                  >
                    <img
                      src="assets/flags/united-states.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Mỹ (US)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerCA);
                    }}
                  >
                    <img
                      src="assets/flags/canada.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Canada (CA)</p>
                    </div>
                  </a>
                </li>
                <p style={{ color: "#083A5E" }}>THEO MỤC ĐÍCH</p>
                <li>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListServerEU);
                    }}
                  >
                    <img
                      src="assets/flags/european-union.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS Châu Âu (PTU)</p>
                    </div>
                  </a>
                </li>
                <li style={{ marginTop: "-16px" }}>
                  <a
                    href=""
                    onClick={() => {
                      navigate(URL.ListVPSGPU);
                    }}
                  >
                    <img
                      src="assets/flags/gpu.png"
                      alt="server"
                      width={45}
                      height={45}
                    ></img>
                    <div className="info">
                      <p>VPS GPU</p>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
