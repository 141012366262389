import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide() {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content blog__banner">
                <span className="starting__price">Hướng dẫn</span>
                <h1 className="banner-title">Danh sách hướng dẫn</h1>
                <p className="slogan">
                  Tổng hợp các hướng dẫn hỗ trợ kĩ thuật.
                </p>
              </div>
              <div className="rts-hosting-banner__image blog">
                <img
                  src="assets/images/banner/banner__blog__image.svg"
                  alt=""
                />
                <img
                  className="shape one left-right"
                  src="assets/images/banner/banner__blog__image-sm1.svg"
                  alt=""
                />
                <img
                  className="shape two show-hide"
                  src="assets/images/banner/banner__blog__image-sm2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="rts-blog-area blog-area-six inner pt--120">
        <div className="container">
          <div className="section-inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="blog-wrapper">
                  <div className="image">
                    <img src="assets/images/blog/blog-5.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="blog-meta">
                      <span className="category">VPS</span>
                      <span className="round" />
                      <span className="date">12 Tháng 10, 2023</span>
                    </p>
                    <h3 className="blog-title animated fadeIn">
                      <a href={URL.Guide1}>Hướng Dẫn Tăng Cường Bảo Mật VPS</a>
                    </h3>
                    <p className="desc">
                      Bạn đang chưa biết có cách nào để tăng cường bảo mật VPS.
                      Hãy đọc bài viết sau để biết thêm chi tiết...
                    </p>
                    <a href={URL.Guide1} className="read-more-btn">
                      Đọc thêm <i className="fa-light fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="blog-wrapper">
                  <div className="image">
                    <img src="assets/images/blog/blog-4.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="blog-meta">
                      <span className="category">Windows Server</span>
                      <span className="round" />
                      <span className="date">12 Tháng 1, 2024</span>
                    </p>
                    <h3 className="blog-title animated fadeIn">
                      <a href={URL.Guide2}>
                        Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                      </a>
                    </h3>
                    <p className="desc">
                      Có 2 cách thông dụng để đổi mật khẩu VPS WS2012 là bằng
                      dòng lệnh hoặc cài đặt trong User Accounts....
                    </p>
                    <a href={URL.Guide2} className="read-more-btn">
                      Đọc thêm <i className="fa-light fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="blog-wrapper">
                  <div className="image">
                    <img src="assets/images/blog/blog-12.png" alt="" />
                  </div>
                  <div className="content">
                    <p className="blog-meta">
                      <span className="category">Tips</span>
                      <span className="round" />
                      <span className="date">02 Tháng 3, 2024</span>
                    </p>
                    <h3 className="blog-title animated fadeIn">
                      <a href={URL.Guide3}>
                        Hướng dẫn mở rộng ổ cứng trên Windows Server và Windows.
                      </a>
                    </h3>
                    <p className="desc">
                      Để mở rộng ổ cứng có thể sử dụng powershell hoặc công cụ
                      Disk Management....
                    </p>
                    <a href={URL.Guide3} className="read-more-btn">
                      Đọc thêm <i className="fa-light fa-angle-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="blog-pagination-area">
            <ul>
              <li>
                <a href="#">
                  <i className="fa-regular fa-chevron-left" />
                </a>
              </li>
              <li>
                <a className="active" href="#">
                  1
                </a>
              </li>
              <li>
                <a href="#">2</a>
              </li>
              <li>
                <a href="#">3</a>
              </li>
              <li>...</li>
              <li>
                <a href="#">8</a>
              </li>
              <li>
                <a href="#">9</a>
              </li>
              <li>
                <a href="#">
                  <i className="fa-regular fa-chevron-right" />
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </section>
    </>
  );
}
