import AgreementContent from "components/Agreement";

export default function Agreement() {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row justify-content-sm-center">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content w-550">
                <span
                  className="starting__price sal-animate"
                  data-sal="slide-down"
                  data-sal-delay={100}
                  data-sal-duration={800}
                >
                  Điều khoản
                </span>
                <h1
                  className="banner-title sal-animate"
                  data-sal="slide-down"
                  data-sal-delay={200}
                  data-sal-duration={800}
                >
                  Điều khoản sử dụng
                </h1>
                <p
                  className="slogan sal-animate"
                  data-sal="slide-down"
                  data-sal-delay={300}
                  data-sal-duration={800}
                >
                  {" "}
                  Bằng cách thông qua chính sách này trước khi khởi tạo dịch vụ.
                  Khách hàng đã chấp nhận các Quy định và điều khoản sử dụng
                  dịch vụ do chúng tôi cung cấp.
                </p>
              </div>
              <div className="rts-hosting-banner__image mt-md-5">
                <img src="assets/images/banner/knowledgebase.png" alt="" />
                <img
                  className="shape-image one"
                  src="assets/images/banner/banner__business__mail__image-sm1.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgreementContent />
    </>
  );
}
