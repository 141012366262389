/* eslint-disable jsx-a11y/role-supports-aria-props */
export default function ListQuestion() {
  return (
    <section className="rts-faq section__padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="rts-faq__first">
              <h3
                className="title sal-animate"
                data-sal="slide-down"
                data-sal-delay={300}
                data-sal-duration={800}
              >
                Những câu hỏi thường gặp
              </h3>
              <p
                data-sal="slide-down"
                data-sal-delay={400}
                data-sal-duration={800}
                className="sal-animate"
              >
                Đội ngũ IT sẵn sàng hỗ trợ 24/7 mọi thắc mắc của quý khách hàng.
              </p>
              <img
                data-sal="slide-down"
                data-sal-delay={500}
                data-sal-duration={800}
                src="assets/images/faq/faq.svg"
                alt="faq"
                className="sal-animate"
              />
              <div className="rts-faq__first--shape">
                <div className="img">
                  <img src="assets/images/faq/faq__animated.svg" alt="" />
                </div>
                <div className="shape-one">VPS</div>
                <div className="shape-two">proxy</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 offset-lg-1">
            <div className="rts-faq__accordion">
              <div className="accordion accordion-flush" id="rts-accordion">
                <div
                  className="accordion-item active sal-animate"
                  data-sal="slide-left"
                  data-sal-delay={300}
                  data-sal-duration={800}
                >
                  <div className="accordion-header" id="first">
                    <h4
                      className="accordion-button collapse show"
                      data-bs-toggle="collapse"
                      data-bs-target="#item__one"
                      aria-expanded="false"
                      aria-controls="item__one"
                    >
                      Tại sao nên mua VPS ở CICLOUD?
                    </h4>
                  </div>
                  <div
                    id="item__one"
                    className="accordion-collapse collapse collapse show"
                    aria-labelledby="first"
                    data-bs-parent="#rts-accordion"
                  >
                    <div className="accordion-body">
                      Chúng tôi trang bị VPS với phần cứng mạnh mẽ, ổ SSD NVME
                      mang đến sự mượt mà khi sử dụng.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item sal-animate"
                  data-sal="slide-left"
                  data-sal-delay={400}
                  data-sal-duration={800}
                >
                  <div className="accordion-header" id="two">
                    <h4
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#item__two"
                      aria-expanded="false"
                      aria-controls="item__two"
                    >
                      Tôi muốn được support trực tiếp thì liên hệ ở đâu?
                    </h4>
                  </div>
                  <div
                    id="item__two"
                    className="accordion-collapse collapse"
                    aria-labelledby="two"
                    data-bs-parent="#rts-accordion"
                  >
                    <div className="accordion-body">
                      Bạn có thể liên hệ qua Page hoặc liên hệ trực tiếp admin
                      qua Tele.
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item sal-animate"
                  data-sal="slide-left"
                  data-sal-delay={600}
                  data-sal-duration={800}
                >
                  <div className="accordion-header" id="four">
                    <h4
                      className="accordion-button collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#item__four"
                      aria-controls="item__four"
                    >
                      Phương thức thanh toán như thế nào?
                    </h4>
                  </div>
                  <div
                    id="item__four"
                    className="accordion-collapse collapse"
                    aria-labelledby="four"
                    data-bs-parent="#rts-accordion"
                  >
                    <div className="accordion-body">
                      Bạn có thể thanh toán thông qua chuyển khoản, momo.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
