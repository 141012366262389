import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function MainFooter() {
  return (
    <footer className="rts-footer site-footer-one section__padding">
      <div className="container">
        <div className="row">
          {/* widget */}
          <div className="col-lg-3 col-md-5 col-sm-6 rts-footer__widget--column">
            <div className="rts-footer__widget footer__widget w-280">
              <a href="" className="footer__logo">
                <img src={`https://buffer.httv.workers.dev/assets/${window.location.hostname}/logo/logo-w.png`} alt="" />
              </a>
              <p className="brand-desc">
                Đơn vị hàng đầu cung cấp các giải pháp hạ tầng IT, điện toán đám
                mây.
              </p>
              <div className="separator site-default-border" />
              <div className="contact-method">
                Chất lượng tốt nhất
                <br />
                Chi phí rẻ nhất
                <br />
                An toàn - Bảo mật
                <br />
                Hỗ trợ 24/7
              </div>
            </div>
          </div>
          {/* widget end */}
          {/* widget */}
          <div className="col-lg-2 col-md-3 col-sm-6 rts-footer__widget--column">
            <div className="rts-footer__widget footer__widget extra-padding">
              <h5 className="widget-title">Thông tin</h5>
              <div className="rts-footer__widget--menu ">
                <ul>
                  <li>
                    <a href={URL.Guide}>Hướng dẫn</a>
                  </li>
                  <li>
                    <a href={URL.Agreement}>Điều khoản</a>
                  </li>
                  <li>
                    <a href={URL.SLA}>SLA</a>
                  </li>
                  <li>
                    <a href={URL.Contact}>liên hệ</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* widget end */}
          {/* widget */}
          <div className="col-lg-2 col-md-4 col-sm-6 rts-footer__widget--column">
            <div className="rts-footer__widget footer__widget extra-padding">
              <h5 className="widget-title">VPS</h5>
              <div className="rts-footer__widget--menu ">
                <ul>
                  <li>
                    <a href={URL.ListServerVN}>Khu vực Việt Nam</a>
                  </li>
                  <li>
                    <a href={URL.ListServerSG}>Khu vực Châu Á</a>
                  </li>
                  <li>
                    <a href={URL.ListServerDE}>Khu vực Châu Âu</a>
                  </li>
                  <li>
                    <a href={URL.ListServerUS}>Khu vực Âu Mỹ</a>
                  </li>
                  <li>
                    <a href={URL.ListVPSGPU}>Theo mục đích</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* widget end */}
          {/* widget */}
          <div className="col-lg-2 col-md-6 col-sm-6 rts-footer__widget--column">
            <div className="rts-footer__widget footer__widget">
              <h5 className="widget-title">Proxy</h5>
              <div className="rts-footer__widget--menu">
                <ul>
                  <li>
                    <a href={URL.ListProxyVN}>Khu vực Việt Nam</a>
                  </li>
                  <li>
                    <a href={URL.ListProxySG}>Khu vực Châu Á</a>
                  </li>
                  <li>
                    <a href={URL.ListProxyDE}>Khu vực Châu Âu</a>
                  </li>
                  <li>
                    <a href={URL.ListProxyUS}>Khu vực Âu Mỹ</a>
                  </li>
                  <li>
                    <a href={URL.List922S5Proxy}>922 Proxy</a>
                  </li>
                  <li>
                    <a href={URL.ListPIAS5Proxy}>PIA Proxy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* widget end */}
          {/* widget */}
          <div className="col-lg-3 col-md-6 rts-footer__widget--column">
            <div className="rts-footer__widget footer__widget">
              <h5 className="widget-title">Liên hệ với chúng tôi</h5>
              <div className="contact-method">
                <a href="tell:121">
                  <span>
                    <i className="fa-regular fa-phone" />
                  </span>
                  +806 (000) 88 99
                </a>
                <a href="mailto:contact@reactheme.com">
                  <span>
                    <i className="fa-light fa-envelope" />
                  </span>
                  contact@cicloud.com
                </a>
              </div>
              <div className="separator site-default-border" />
              <div className="social__media">
                <h5>Mạng xã hội</h5>
                <div className="social__media--list">
                  <a href="#" className="media">
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                  <a href="#" className="media">
                    <i className="fa-brands fa-telegram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* widget end */}
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="rts-footer__copyright text-center">
            <p>© Copyright 2024. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}
