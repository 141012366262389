import ListFeaturePlan from "components/FeaturePlan";
import { BannerBuyNow } from "./BannerBuyNow";
import ListQuestion from "./ListQuestion";
import MainIntro from "./MainIntro";
import OurBestServices from "./OurBestServices";
import WhyChooseUs from "./WhyChooseUs";

export default function Content() {
  return (
    <>
      <MainIntro/>
      <ListFeaturePlan/>
      <OurBestServices/>
      <WhyChooseUs/>
      <ListQuestion/>
      <BannerBuyNow/>
    </>
  );
}
