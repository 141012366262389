import URL from "constant/url";
import GuideRightContent from "./GuideRightContent";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Guide2() {
  return (
    <>
      <div className="rts-hosting-banner rts-hosting-banner-bg banner-default-height">
        <div className="container">
          <div className="row">
            <div className="banner-area">
              <div className="rts-hosting-banner rts-hosting-banner__content blog__banner">
                <span className="starting__price">Hướng dẫn</span>
                <h1 className="banner-title">
                  Hướng Dẫn Đổi Password VPS Sử Dụng Windows Server 2012
                </h1>
              </div>
              <div className="rts-hosting-banner__image blog">
                <img
                  src="assets/images/banner/banner__blog__image.svg"
                  alt=""
                />
                <img
                  className="shape one left-right"
                  src="assets/images/banner/banner__blog__image-sm1.svg"
                  alt=""
                />
                <img
                  className="shape two show-hide"
                  src="assets/images/banner/banner__blog__image-sm2.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="rts-blog-details section__padding"
        style={{ paddingBottom: 0 }}
      >
        <div className="container">
          <div className="row g-40">
            <div className="col-lg-8">
              <article className="blog-details">
                <div className="blog-details__article-meta">
                  <a href="#">
                    <span>
                      <i className="fa-light fa-user" />
                    </span>
                    Admin
                  </a>
                  <span>
                    <span>
                      <i className="fa-light fa-clock" />
                    </span>
                    12 Tháng 1, 2024
                  </span>
                  <a href="#">
                    <span>
                      <i className="fa-sharp fa-light fa-tags" />
                    </span>
                    Windows Server
                  </a>
                </div>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <span style={{ fontSize: 20 }}>
                      <span style={{ color: "#e74c3c" }}>
                        <strong>
                          Cách 1: Mở CMD hoặc Powershell và chạy dòng lệnh
                        </strong>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <strong>
                      <em>Net user administrator Mậtkhẩubạnmuốnđổi</em>
                    </strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <strong>
                      <em>ví dụ: Net user administrator cicloud@12345</em>
                    </strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <em>
                      Lưu ý: Mật khẩu phải bao gồm chữ IN HOA và chữ thường (nếu
                      thêm ký tự đặc biệt càng tốt)
                    </em>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <span style={{ color: "#e74c3c" }}>
                      <span style={{ fontSize: 20 }}>
                        <strong>
                          Cách 2: Thay đổi mật khẩu theo cách truyền thống
                        </strong>
                      </span>
                    </span>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    - Đầu tiên bạn vào&nbsp;<strong>Start</strong>
                    &nbsp;chọn&nbsp;
                    <strong>Control Panel</strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <a
                      href="https://image.maxserver.com/image/qWi"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="1.png"
                        src="https://image.maxserver.com/images/2016/04/11/1.png"
                        style={{ width: "100%" }}
                      />
                    </a>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    - Tiếp theo bạn chọn&nbsp;
                    <strong>Change account type</strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <a
                      href="https://image.maxserver.com/image/4Bc"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="2.png"
                        src="https://image.maxserver.com/images/2016/04/11/2.png"
                        style={{ width: "100%" }}
                      />
                    </a>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    - Click vào tài khoản cần đổi mật khẩu, ở đây là tài
                    khoản&nbsp;
                    <strong>Administrator</strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <a
                      href="https://image.maxserver.com/image/GJD"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="3.png"
                        src="https://image.maxserver.com/images/2016/04/11/3.png"
                        style={{ width: "100%" }}
                      />
                    </a>
                  </span>
                </p>
                <p>&nbsp;</p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    - Click vào dòng&nbsp;
                    <strong>Change the password</strong>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <a
                      href="https://image.maxserver.com/image/TTr"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="4.png"
                        src="https://image.maxserver.com/images/2016/04/11/4.png"
                        style={{ width: "100%" }}
                      />
                    </a>
                  </span>
                </p>
                <p>&nbsp;</p>
                <ul>
                  <li>
                    <span
                      style={{
                        fontFamily: "Arial,Helvetica,sans-serif",
                      }}
                    >
                      Bạn nhập mật khẩu như&nbsp;hướng dẫn ở dưới, mật khẩu cần
                      có chữ in hoa, chữ thường, kí tự đặc biệt sẽ giúp bảo mật
                      tốt hơn.
                    </span>
                  </li>
                </ul>
                <p>&nbsp;</p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    <a
                      href="https://image.maxserver.com/image/nUM"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        alt="5.png"
                        src="https://image.maxserver.com/images/2016/04/11/5.png"
                        style={{ width: "100%" }}
                      />
                    </a>
                  </span>
                </p>
                <p>
                  <span
                    style={{
                      fontFamily: "Arial,Helvetica,sans-serif",
                    }}
                  >
                    - Cuối cùng&nbsp;bạn click vào&nbsp;
                    <strong>Change password</strong>
                    &nbsp;là xong.
                  </span>
                </p>
              </article>
            </div>
            <GuideRightContent
              children={
                <>
                  <div className="single-post">
                    <div className="thumb">
                      <img
                        src="assets/images/blog/blog-5.png"
                        alt=""
                        height={85}
                        width={85}
                      />
                    </div>
                    <div className="meta">
                      <span className="published">
                        <i className="fa-regular fa-clock" />
                        12 Tháng 10, 2023
                      </span>
                      <h6 className="title">
                        <a href={URL.Guide1}>
                          Hướng Dẫn Tăng Cường Bảo Mật VPS
                        </a>
                      </h6>
                    </div>
                  </div>
                  <div className="single-post">
                    <div className="thumb">
                      <img
                        src="assets/images/blog/blog-12.png"
                        alt=""
                        height={85}
                        width={85}
                      />
                    </div>
                    <div className="meta">
                      <span className="published">
                        <i className="fa-regular fa-clock" /> 02 Tháng 3, 2024
                      </span>
                      <h6 className="title">
                        <a href={URL.Guide3}>
                          Hướng dẫn mở rộng ổ cứng trên Windows Server và
                          Windows.
                        </a>
                      </h6>
                    </div>
                  </div>
                </>
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
