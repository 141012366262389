import URL from "constant/url";

/* eslint-disable jsx-a11y/anchor-is-valid */
export function BannerBuyNow() {
  return (
    <div className="rts-cta">
      <div className="container">
        <div className="row">
          <div className="rts-cta__wrapper">
            <div className="rts-cta__left">
              <h3
                className="cta__title sal-animate"
                data-sal="slide-down"
                data-sal-delay={300}
                data-sal-duration={800}
              >
                Trải nghiệm ngay hôm nay để hưởng ưu đãi tốt nhất!
              </h3>
              <p
                data-sal="slide-down"
                data-sal-delay={400}
                data-sal-duration={800}
                className="sal-animate"
              >
                Trên tất cả, với uy tín và kinh nghiệm trên thị trường. Chúng tôi luôn cố gắng mang đến cho quý khách một trải nghiệm tốt nhất.
              </p>
              <a
                data-sal="slide-down"
                data-sal-delay={500}
                data-sal-duration={800}
                href={URL.BuyProduct}
                className="primary__btn secondary__bg sal-animate"
              >
                mua ngay <i className="fa-regular fa-arrow-right" />
              </a>
            </div>
            <div className="rts-cta__right">
              <div className="cta-image">
                <div className="cta-image__one">
                  <img src="assets/images/cta/cta__hosting.svg" alt="" />
                </div>
                <div className="cta-image__two">
                  <img src="assets/images/cta/cta__person.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
