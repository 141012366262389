const URL = {
  Home: "/",
  SLA: "/cam-ket-chat-luong-dich-vu",
  Guide: "/huong-dan",
  Guide1: "/huong-dan-tang-cuong-bao-mat-vps",
  Guide2: "/huong-dan-doi-password-vps-su-dung-windows-server-2012",
  Guide3: "/huong-dan-mo-rong-o-cung-trên-windows-server-va-windows",
  Agreement: "/dieu-khoan",
  Contact: "/lien-he",
  ListServerVN: "/danh-sach-vps-viet-nam",
  ListServerSG: "/danh-sach-vps-singapore",
  ListServerDE: "/danh-sach-vps-duc",
  ListServerFR: "/danh-sach-vps-phap",
  ListServerAU: "/danh-sach-vps-uc",
  ListServerUK: "/danh-sach-vps-anh",
  ListServerUS: "/danh-sach-vps-my",
  ListServerCA: "/danh-sach-vps-canada",
  ListServerEU: "/danh-sach-vps-chau-au",
  ListServerNL: "/danh-sach-vps-ha-lan",
  ListVPSGPU: "/danh-sach-vps-gpu",
  ListProxyVN: "/danh-sach-proxy-viet-nam",
  ListProxySG: "/danh-sach-proxy-singapore",
  ListProxyDE: "/danh-sach-proxy-duc",
  ListProxyFR: "/danh-sach-proxy-viet-phap",
  ListProxyAU: "/danh-sach-proxy-uc",
  ListProxyUK: "/danh-sach-proxy-anh",
  ListProxyUS: "/danh-sach-proxy-my",
  ListProxyCA: "/danh-sach-proxy-canada",
  ListProxyNL: "/danh-sach-proxy-ha-lan",
  ListDedicatedServerEU: "/danh-sach-dedicated-server-chau-au",
  ListDedicatedServerUS: "/danh-sach-dedicated-server-my",
  List922S5Proxy: "/danh-sach-922-s5-proxy",
  ListPIAS5Proxy: "/danh-sach-pia-s5-proxy",
  BuyProduct: "https://manage.cicloud.vn/buying?type=VPS&nation=VN",
  Login: "https://manage.cicloud.vn",
  Register: "https://manage.cicloud.vn/register",
  ProductManagement: "https://manage.cicloud.vn/management?type=VPS&by_time=using",
  NotFound: "*",
};

export default URL;
